import * as Actions from '../../../../redux/actions';

import { DeleteIcon, DownArrowIcon, PlusSignIcon, UpArrowIcon } from '../SVGBank'

import ActivitiesDestinationDays from './ActivitiesDestinationDays';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hotels from './Hotels';
import NeedFlights from './NeedFlights';
import React from 'react'
import TimeToReachDestinations from './TimeToReachDestinations';
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { image_boxing, list_container_mode, URL_REPLACE } from '../RegExValidate';
import { Image } from 'react-bootstrap';
import TimelineBookedHotel from './TimelineBookedHotel';
import TimelineSavedHotel from './TimelineSavedHotel';
import { getTimelineFlights, getTimelineHotels } from '../Trip/ActiveTrip.slice';
import TimelineSavedFlight from './TimelineSavedFlight';


export const TripDestinationTabPanel = connect((state) => ({
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeDay: state.FormBank.TimelineWizard.activeDay,
    listDestinationId: state.Destination.destination.activeId,
    activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
    savedTrip: state.ActiveTrip,
    activeId: state.Destination.destination.activeId,
    tripMode: state.Member.tripMode,
    timelineWizard: state.FormBank.TimelineWizard,
}), {
    fetchDestination: Actions.fetchDestination,
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
    fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,
    fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
    fetchEstablishmentSortingOptions: Actions.fetchEstablishmentSortingOptions,
    fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
    fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
    setDestinationId: Actions.setDestinationId,
    setTimelineTripDestinationOpenStatus: Actions.setTimelineTripDestinationOpenStatus,
    dragDropTripDestinatinInTrip: Actions.dragDropTripDestinatinInTrip,
    setActiveTripDestinationId: Actions.setActiveTripDestinationId,
    deleteTripDestinationInTrip: Actions.deleteTripDestinationInTrip,
    addDayToTripDestinationInTrip: Actions.addDayToTripDestinationInTrip,
    setTimelineTripDestinationStatus: Actions.setTimelineTripDestinationStatus,
},
)(({
    activeTrip,
    setTimelineTripDestinationStatus,
    tripId = activeTrip.tripId,
    tripDuration = activeTrip.tripDuration,
    tripStartDate = activeTrip.tripStartDate,
    tripNoDate = activeTrip.tripNoDate,
    uuid_auth,
    timelineWizard,
    uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID",
    destinations = activeTrip.tripDestinations,
    tripDestinationsOpenStatus = activeTrip.tripDestinationsOpenStatus,
    activeTripDestinationId,
    activeDestinationId,
    lang,
    activeDay,
    activeId,
    setDestinationId,
    fetchDestination,
    fetchDestinationPhotoGallery,
    tripMode,
    fetchEstablishmentSuggestedTrips,
    fetchDestinationFeaturesAndTypes,
    fetchEstablishmentSortingOptions,
    fetchEstablishmentInfluencers,
    setTimelineTripDestinationOpenStatus,
    dragDropTripDestinatinInTrip,
    setActiveTripDestinationId,
    deleteTripDestinationInTrip,
    addDayToTripDestinationInTrip,
    listDestinationId,
    savedTrip,
    setTripDisplayMode,
    setPageYOffset
}) => {
    const [isDraggingId, setDraggingId] = React.useState(null)
    const [hasHotel, setHotel] = React.useState(false)
    const bookingDestination = useSelector(state => state.ActiveTrip.bookingDestination);


    const fetchData = (id, lang, tripDestinationId, destination, open = "", fetch = true) => {
        if (fetch && ((destinations.length === 1 && activeDestinationId !== listDestinationId) ||
            (destinations.length > 1 && activeDestinationId !== id))) {
            setDestinationId(id)
            fetchDestination(id, lang)
            fetchDestinationPhotoGallery(id, lang)
            fetchEstablishmentSuggestedTrips(id, lang)
            fetchDestinationFeaturesAndTypes(id, lang)
            fetchEstablishmentSortingOptions(lang)
            fetchEstablishmentInfluencers(id, lang)
        }
        // open === "OPENSELECTED" && setTimelineTripDestinationOpenStatus(tripDestinationId, "OPENSELECTED")
        setActiveTripDestinationId(destination)
    }
    const deleteTripDestinationInTripAndActivateDestination = (lang, uuid, destination, index_destination) => {
        if (destinations !== undefined && destinations.length > 1) {
            if (activeTripDestinationId === destination.td_id) {
                if (index_destination === 0) {
                    destinations[1] !== undefined && fetchData(destinations[1].id, lang, destinations[1].td_id, destinations[1], "", false)
                } else {
                    destinations[index_destination - 1] !== undefined
                        && fetchData(destinations[index_destination - 1].id, lang, destinations[index_destination - 1].td_id, destinations[index_destination - 1], "", false)
                }
            }
            deleteTripDestinationInTrip(lang, uuid, destination, tripId, tripDuration)
        }
    }
    const onDragOverDestination = (event) => {
        event.preventDefault()
        event.stopPropagation()
        event.dataTransfer.dropEffect = "move"
    }
    const onDragEnterDestination = (event, index_destination_drag) => {
        event.dataTransfer.dropEffect = "move"
    }
    const onDragStartDestination = (event, index_destination_drag) => {
        event.dataTransfer.setData("index_destination_drag", index_destination_drag)
        setTimelineTripDestinationOpenStatus("", "CLOSEALL")
        setDraggingId(index_destination_drag)
        event.dataTransfer.effectAllowed = "move"
    }
    const onDropDestination = (event, index_destination_drop) => {
        const index_destination_drag = event.dataTransfer.getData("index_destination_drag")
        if (destinations !== undefined && destinations.length > 0
            && destinations[index_destination_drag] !== undefined
            && destinations[index_destination_drag].td_id !== undefined) {
            const destinationsRearrange = Array.from(new Set(destinations.filter(d => d.td_id !== destinations[index_destination_drag].td_id)))
            destinationsRearrange.splice(index_destination_drop, 0, destinations[index_destination_drag])
            dragDropTripDestinatinInTrip(lang, uuid, destinationsRearrange, tripId)

            setTimeout(() => {

                setTimelineTripDestinationStatus(tripDestinationsOpenStatus)

            }, 500); ///keep original state

            setDraggingId(null)
        }
    }

    const [currentPosition, setCurrentPosition] = React.useState(null);
    const [lastUpdate, setLastUpdate] = React.useState(0);

    const success = position => {
        //console.log("TimeToReachDestinationPanel navigator.getCurrentPosition success")
        //console.log(position)
        const currentPosition = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        }
        setCurrentPosition(currentPosition);
        //console.log(`position: ${position.coords.latitude} ${position.coords.longitude}`)
        setLastUpdate(Date.now())
    };
    const history = useHistory()

    const goToMap = () => {
        setTripDisplayMode(list_container_mode.map)
        setPageYOffset(0)
    }

    const selectDestination = (destination) => {
        setActiveTripDestinationId(destination)
        const url = "/destination/" + destination.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + destination.destId;
        history.push(url.toLowerCase())
        if (tripMode === list_container_mode.map) {
            setTimeout(() => {
                goToMap()
            }, 1000);
        }



    }
    const failure = err => {
        // console.log("TimeToReachDestinationPanel navigator.getCurrentPosition failed")
        // console.log(err)
        setCurrentPosition(null);
    };


    const isRoadTrip = savedTrip && savedTrip?.trip && savedTrip?.trip?.tripTravelCategory?.code === "road";
    const timelineHotels = useSelector(state => state.ActiveTrip.timelineHotels);
    const timelineFlights = useSelector(state => state.ActiveTrip.timelineFlights);
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (activeTrip.addedActivityID) {
            const activitiesInActiveDestinationDay = activeTrip.activities && activeTrip.activities.filter(a => a.activityDayInDestination === activeDay.dayInTripDestination && a.tripDestinationId === activeDay.tripDestinationId)
            let activeActivity = activitiesInActiveDestinationDay && activitiesInActiveDestinationDay.find(activity => activity.activityEstabID === activeTrip.addedActivityID);
            let ID = activeActivity && activeActivity.activityID;
            if (activeActivity && activeActivity.tripDestinationId && ID) {
                setTimelineTripDestinationOpenStatus(activeActivity.tripDestinationId, "OPENSELECTED")
                setTimeout(() => {
                    if (document.getElementById("drag_activity_" + ID)) {
                        document.getElementById("drag_activity_" + ID).scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                }, 500);
            }
        }
    }, [activeTrip.addedActivityID, activeTrip.activities])


    React.useEffect(() => {
        if (activeTrip.addedActivityID) {
            setTimeout(() => {
                if (document.getElementById("hotel_" + activeTrip.addedActivityID)) {
                    document.getElementById("hotel_" + activeTrip.addedActivityID).scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            }, 500);
        }
    }, [activeTrip.addedActivityID, timelineHotels])

    React.useEffect(() => { ///keep destination state
        if (bookingDestination && activeTrip?.tripDestinations?.length > 0) {

            setTimeout(() => {
                setTimelineTripDestinationOpenStatus(bookingDestination?.td_id, "OPENSELECTED")
            }, 3000);
        }
    }, [activeTrip, bookingDestination])



    React.useEffect(() => {
        if (tripId) {
            dispatch(getTimelineHotels({
                uuid: uuid,
                tripId: tripId
            }))
            dispatch(getTimelineFlights({
                uuid: uuid,
                tripId: tripId
            }))
        }
    }, [tripId])



    React.useEffect(() => {

        if (destinations && destinations?.length > 0) {
            const activeDest = destinations?.find((dest) => dest?.id === activeId)
            if (activeDest) {
                setTimelineTripDestinationOpenStatus(activeDest?.td_id, "OPENSELECTED")
            }
        }
    }, [destinations, activeId])




    const muvHotels = timelineHotels && timelineHotels.muvHotels ? timelineHotels.muvHotels : null;
    const customHotels = timelineHotels && timelineHotels.customHotels ? timelineHotels.customHotels : null;
    const hasSavedFlights = timelineFlights && timelineFlights?.length > 0;



    React.useEffect(() => {

        if (timelineHotels && (timelineHotels.muvHotels || timelineHotels.customHotels)) {
            setHotel(true)
        }
        setHotel(false)
    }, [timelineHotels, activeId])





    const hotelList = muvHotels && muvHotels?.length > 0 ? muvHotels : customHotels;





    return (
        <div style={{
            paddingTop: 0, backgroundColor: "#f8f8f8",
            overflowX: "hidden"
        }}>


            {destinations.length > 0 && destinations.map((destination, index_destination) => {
                const cityName = destination && destination.customDestinationData ? destination.customDestinationData.city_name : destination.name || "";
                const dayAndNight = destination.duration === "1" ? "1 day"
                    : destination.duration === "2" ? "2 day / 1 night"
                        : destination.duration + " days / " + (destination.duration - 1) + " nights"
                const destinationStartingDate = tripNoDate === "1" ? "Day " + destination.startingDayInTrip
                    : moment(tripStartDate).add(destination.startingDayInTrip - 1, "days").format('ddd, MMMM Do, YYYY')


                const isPlaneTransport = destination?.transportMode === "plane"

                const destinationFlights = timelineFlights && timelineFlights?.length > 0 ?
                    timelineFlights?.filter((flight) => flight?.trip_destinations_id === destination?.td_id) : null;



                const destinationHotels = hotelList && hotelList?.length > 0 ?
                    hotelList?.filter((hotel) => (hotel?.trip_destinations_id === destination?.td_id || hotel?.tripDestinationId === destination?.td_id)) : null;


                return (

                    <ClickAwayListener key={index_destination} onClickAway={() => setDraggingId(null)}>
                        <React.Fragment>
                            {/* departure */}
                            {destinationFlights && destinationFlights?.length > 0 && destinationFlights.map((flight, index) => {

                                if (!flight?.return_leg_from_trip_destination_id) {
                                    return (<div key={index}>
                                        <TimelineSavedFlight flight={flight}
                                            destinationFlights={destinationFlights}
                                            destination={destination}
                                            nextFlight={destinationFlights[1]} />
                                    </div>)
                                }
                            })
                            }
                            <TimeToReachDestinations destination={destination} index_destination={index_destination}
                                hasSavedFlights={hasSavedFlights} />
                            <div id={destination.td_id}
                                draggable={destinations.length > 1 ? true : false}
                                onDragStart={(e) => destinations.length > 1 ? onDragStartDestination(e, index_destination) : {}}
                                onDragOver={(e) => destinations.length > 1 ? onDragOverDestination(e) : {}}
                                onDragEnter={(e) => destinations.length > 1 ? onDragEnterDestination(e, index_destination) : {}}
                                onDragEnd={(e) => setDraggingId(null)}
                                onDrop={(e) => destinations.length > 1 ? onDropDestination(e, index_destination) : {}}
                                style={{
                                    backgroundColor: isDraggingId === index_destination && destinations.length > 1 ? "rgba(25, 188, 155, 0.1)" : "#F8F8F8",
                                    paddingTop: "7px",
                                    position: "relative",
                                    borderTop: isDraggingId !== null && isDraggingId > index_destination ? "3px solid rgba(25, 188, 155, 0.5)" : "",
                                    borderBottom: isDraggingId !== null && isDraggingId < index_destination ? "3px solid rgba(25, 188, 155, 0.5)" : "",
                                    paddingBottom: isDraggingId !== null && isDraggingId < index_destination ? "7px" : 0,
                                }}
                                className={destinations.length > 1 ? "draggable" : ""}
                            >
                                {<TripDestination destination={destination} cityName={cityName} selectDestination={selectDestination}
                                    destinations={destinations} tripDestinationsOpenStatus={tripDestinationsOpenStatus} deleteTripDestinationInTripAndActivateDestination={deleteTripDestinationInTripAndActivateDestination}
                                    setTimelineTripDestinationOpenStatus={setTimelineTripDestinationOpenStatus} lang={lang} uuid={uuid} index_destination={index_destination}
                                    activeDestinationId={activeDestinationId} dayAndNight={dayAndNight} />}
                            </div>


                            {tripDestinationsOpenStatus.find(d => d.tripDestinationId === destination.td_id && d.openStatus === true) ? <div style={{
                                backgroundColor: "#FFFFFF",
                                marginBottom: "7px",
                                paddingTop: "1px",
                            }}>
                                {!isRoadTrip && isPlaneTransport && !(destinationFlights?.length > 0) && <NeedFlights destination={destination} openDefault={true}
                                    activeTrip={activeTrip} />}






                                {!(destinationHotels?.length > 0) && !hasHotel && <Hotels destination={destination} openDefault={true} />}

                                {timelineHotels && timelineHotels.customHotels &&
                                    timelineHotels.customHotels.map((hotel, index) => {
                                        if (hotel?.tripDestinationId === destination?.td_id) {
                                            return (<div key={index} id={"hotel_" + hotel?.tripDestinationId}>
                                                {hotel?.booking_status === "Confirmed" ?
                                                    <TimelineBookedHotel hotel={hotel} /> : <TimelineSavedHotel hotel={hotel} destination={destination} />}
                                            </div>)
                                        }
                                    })}

                                {timelineHotels && muvHotels && muvHotels.length > 0 &&
                                    muvHotels.map((hotel, index) => {
                                        if (hotel?.tripDestinationId === destination?.td_id) {
                                            return (<div key={index} id={"hotel_" + hotel?.activityEstabID}>
                                                {hotel?.booking_status === "Confirmed" ?
                                                    <TimelineBookedHotel hotel={hotel} /> : <TimelineSavedHotel hotel={hotel} destination={destination} />}
                                            </div>)
                                        }
                                    })}


                                <ActivitiesDestinationDays destination={destination} />


                                <div style={{
                                    margin: "10px",
                                    marginRight: "0px",
                                    padding: "10px", textAlign: "center", font: "16px/19px Futura Hv BT", color: "#0D3A7D", backgroundColor: "var(--mainWhite)", border: "2px solid #0D3A7D"
                                }}
                                    onClick={() => addDayToTripDestinationInTrip(lang, uuid, destination, tripId, tripDuration)}
                                >
                                    <PlusSignIcon style={{ marginRight: "5px" }} width={11} height={12} fill={"#0D3A7D"} />{"Add a day in " + destination.name}
                                </div>
                                <div style={{ padding: "1px", }} ></div>

                                {/* //return flights */}
                                {/* departure */}
                                {destinationFlights && destinationFlights?.length > 0 && destinationFlights.map((flight, index) => {

                                    if (flight?.return_leg_from_trip_destination_id) {
                                        return (<div key={index}>
                                            <TimelineSavedFlight flight={flight}
                                                destination={destination}
                                                destinationFlight={destinationFlights}
                                                nextFlight={destinationFlights[0]} />
                                        </div>)
                                    }
                                })
                                }

                            </div>
                                : <div style={{ marginBottom: "7px", }}></div>}
                        </React.Fragment>
                    </ClickAwayListener>
                )
            })}
        </div >
    )
})

const TripDestination = ({ destination, activeDestinationId, selectDestination, cityName, dayAndNight, destinations,
    tripDestinationsOpenStatus, deleteTripDestinationInTripAndActivateDestination, setTimelineTripDestinationOpenStatus, lang, uuid, index_destination }) => {

    return (
        <div>
            <div style={{ backgroundColor: "black" }}>
                <Image
                    src={image_boxing(destination.destinationImage, "375", "0")}
                    alt="destination image" height={80} style={{ opacity: '0.8', width: '100%' }} />
                <div style={{
                    position: 'absolute', top: 30, left: 30, color: 'white',
                    cursor: activeDestinationId === destination.td ? "" : "pointer",
                }} onClick={() => { if (parseInt(destination.is_custom) === 0) selectDestination(destination) }}>
                    <span style={{ textTransform: 'uppercase', font: "18px/24px Futura Hv BT" }}>{cityName}
                    </span>
                    <span style={{
                        font: "14px/24px Futura Lt BT",
                        color: "white",
                        marginLeft: "5px",
                    }}>({dayAndNight})</span>


                </div>

                {destinations.length > 1 && <div style={{ position: "absolute", top: "13px", right: "2px", cursor: "pointer" }}
                    onClick={() => deleteTripDestinationInTripAndActivateDestination(lang, uuid, destination, index_destination)}>
                    <DeleteIcon fill={'white'} />
                </div>}
                {tripDestinationsOpenStatus.find(d => d.tripDestinationId === destination.td_id && d.openStatus === true) ?
                    <div style={{ position: "absolute", top: "50px", right: "14px", cursor: "pointer" }}
                        onClick={() => setTimelineTripDestinationOpenStatus(destination.td_id, "", destination)}>
                        <UpArrowIcon fill={'white'} width={20} height={25} />
                    </div>
                    :
                    <div style={{ position: "absolute", top: "50px", right: "14px", cursor: "pointer" }}
                        onClick={() => setTimelineTripDestinationOpenStatus(destination.td_id, "", destination)}>
                        <DownArrowIcon fill={'white'} width={20} height={25} />
                    </div>
                }

            </div>



        </div>
    )
}