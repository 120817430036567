export const generateICSFile = (activity, dayInDestination, startDate) => {
    try {
        if (!activity || !activity.activityDuration || !activity.activityStartTime) {
            throw new Error("Invalid activity data. Missing required fields.");
        }

        const [year, month, day] = startDate.split('-').map(Number);
        const baseDate = new Date(year, month - 1, day);
        baseDate.setDate(baseDate.getDate() + Number(dayInDestination) - 1);

        const [hours, minutes] = activity.activityStartTime.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) throw new Error("Invalid start time format.");

        const startDateTime = new Date(baseDate);
        if (activity.activityNoTime === "1") {
            startDateTime.setHours(hours + 9, minutes, 0, 0); // Add 9 hours to given time
        } else {
            startDateTime.setHours(hours, minutes, 0, 0);
        }

        const [durationHours, durationMinutes] = activity.activityDuration.split(':').map(Number);
        const endDateTime = new Date(startDateTime);
        endDateTime.setHours(endDateTime.getHours() + durationHours);
        endDateTime.setMinutes(endDateTime.getMinutes() + durationMinutes);

        const formatICSDate = (date) => date.toISOString().replace(/-|:|\.\d+/g, "");

        const icsData = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${formatICSDate(startDateTime)}
DTEND:${formatICSDate(endDateTime)}
SUMMARY:${activity.activityName || "Event"}
DESCRIPTION:${activity.activityDescription || ""}
LOCATION:${activity.activityAddress || ""}
END:VEVENT
END:VCALENDAR`;

        const blob = new Blob([icsData], { type: "text/calendar" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${activity.activityName || "event"}.ics`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.error("Error generating ICS file:", error.message);
    }
};


export const generateGoogleCalendarLink = (activity, dayInDestination, startDate) => {
    try {
        if (!activity || !activity.activityDuration || !activity.activityStartTime) {
            throw new Error("Invalid activity data. Missing required fields.");
        }

        const [year, month, day] = startDate.split('-').map(Number);
        const baseDate = new Date(year, month - 1, day);
        baseDate.setDate(baseDate.getDate() + Number(dayInDestination) - 1);

        const [hours, minutes] = activity.activityStartTime.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) throw new Error("Invalid start time format.");

        const startDateTime = new Date(baseDate);
        if (activity.activityNoTime === "1") {
            startDateTime.setHours(hours + 9, minutes, 0, 0); // Add 9 hours to provided time
        } else {
            startDateTime.setHours(hours, minutes, 0, 0);
        }

        const [durationHours, durationMinutes] = activity.activityDuration.split(':').map(Number);
        const endDateTime = new Date(startDateTime);
        endDateTime.setHours(endDateTime.getHours() + durationHours);
        endDateTime.setMinutes(endDateTime.getMinutes() + durationMinutes);

        const format = (date) => date.toISOString().replace(/-|:|\.\d+/g, "");
        const formattedStart = format(startDateTime);
        const formattedEnd = format(endDateTime);

        return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(activity.activityName || "Event")}&details=${encodeURIComponent(activity.activityDescription || "")}&location=${encodeURIComponent(activity.activityAddress || "")}&dates=${formattedStart}/${formattedEnd}`;
    } catch (error) {
        console.error("Error generating Google Calendar link:", error.message);
        return null;
    }
};


export const generateOutlookCalendarLink = (activity, dayInDestination, startDate) => {
    try {
        if (!activity || !activity.activityDuration || !activity.activityStartTime) {
            throw new Error("Invalid activity data. Missing required fields.");
        }

        const [year, month, day] = startDate.split('-').map(Number);
        const baseDate = new Date(year, month - 1, day);
        baseDate.setDate(baseDate.getDate() + Number(dayInDestination) - 1);

        const [hours, minutes] = activity.activityStartTime.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) throw new Error("Invalid start time format.");

        const startDateTime = new Date(baseDate);
        if (activity.activityNoTime === "1") {
            startDateTime.setHours(hours + 9, minutes, 0, 0); // Add 9 hours for "no time" activities
        } else {
            startDateTime.setHours(hours, minutes, 0, 0);
        }

        const [durationHours, durationMinutes] = activity.activityDuration.split(':').map(Number);
        const endDateTime = new Date(startDateTime);
        endDateTime.setHours(endDateTime.getHours() + durationHours);
        endDateTime.setMinutes(endDateTime.getMinutes() + durationMinutes);

        // Format to ISO8601 without milliseconds
        const formatOutlookDate = (date) => encodeURIComponent(date.toISOString().slice(0, 19));

        const subject = encodeURIComponent(activity.activityName || "Event");
        const body = encodeURIComponent(
            (activity.activityDescription || "") +
            (activity.activityUrl ? `\n\nMore info: ${activity.activityUrl}` : "")
        );
        const location = encodeURIComponent(activity.activityAddress || "");

        const link = `https://outlook.live.com/calendar/0/action/compose?allday=false&startdt=${formatOutlookDate(startDateTime)}&enddt=${formatOutlookDate(endDateTime)}&subject=${subject}&body=${body}&location=${location}&path=/calendar/action/compose&rru=addevent`;

        window.open(link, '_blank');

    } catch (error) {
        console.error("Error generating Outlook Calendar link:", error.message);
        return "";
    }
};
