import React, { useEffect, useState } from 'react';
import FlightUpgradeOptions from './FlightUpgradeOptions.jsx';
import { checkFlightAvailable } from './services/Flights.thunks';
import { useSelector } from 'react-redux';

function FlightUpgradeContainer({ flightData, selectFlight, itinerary_data, fares }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const { uuid } = useSelector(state => state.Member.authModal);

    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";

    useEffect(() => {
        async function loadFares() {
            try {
                setLoading(true);
                const ppnId = flightData?.ppn_contract_bundle;
                const res = await checkFlightAvailable(ppnId, fullCurrency);
                const flightFares = res?.itinerary_data?.upsell_options || [];

                // setItinerary(res?.itinerary_data);
                // setFares(flightFares);
            } catch (err) {
                setError('Failed to fetch flight fares');
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        // loadFares();
    }, []);

    const wrapperStyle = {
        border: '1px solid #DFDFDF',
        padding: '20px',
        marginTop: '15px',
        borderRadius: 0,
        backgroundColor: '#fff',
    };

    if (loading) return <div style={wrapperStyle}>Loading upgrade options...</div>;
    if (error) return <div style={wrapperStyle}>{error}</div>;
    if (!fares.length) return <div style={wrapperStyle}>No fares available</div>;

    return <FlightUpgradeOptions fares={fares} selectFlight={selectFlight} itinerary_data={itinerary_data} />;
}

export default FlightUpgradeContainer;
