import React from 'react'
import { Backdrop, CircularProgress, Link } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { PersonalizedModalUseStyles } from './Components/WebContent/FeaturePage/personalizedTrip/personalizedTripStyles'
import { useHistory } from "react-router-dom"
import { environment } from './Environments/environment'
import AiChatBox from './Components/WebContent/Common/AiChatBox/AiChatBox'
import { useLocation } from 'react-router-dom'
import EstablishmentChatBox from './Components/WebContent/Common/HotelChatBox/HotelChatBox'


const ChatBox = () => {

    let location = useLocation()



    const pathname = location?.pathname;



    const isEstablishment = (pathname?.includes("establishment"));
    const isDestination = (pathname?.includes("destination")) || (pathname?.includes("hotel-search"));;
    const showChatBox = pathname?.includes("home") || pathname === "/" || pathname?.includes("flight") || pathname?.includes("/trip")


    const showHotelChatBox = (isEstablishment || isDestination || pathname?.includes("hotel") || pathname?.includes("booking"))







    return (
        <React.Fragment>
            {showChatBox &&
                <AiChatBox />}

            {showHotelChatBox &&
                <EstablishmentChatBox
                    isDestination={isDestination}
                    isEstablishment={isEstablishment} />}
        </React.Fragment>
    )
}


const BaseComponents = () => {
    const savedPersonalTrip = useSelector(state => state.PersonalizedTrip.aiTrip.saved)
    const aiCreating = useSelector(state => state.PersonalizedTrip.aiTrip.loading)
    const classes = PersonalizedModalUseStyles();
    const history = useHistory();

    React.useEffect(() => {

    }, [])


    return (
        <div>
            {aiCreating && <Backdrop className={classes.backdrop} open={aiCreating} >
                <div style={{
                    font: "22px/18px Futura Lt BT",
                    color: "white",
                    paddingLeft: '0px',
                    marginBottom: '20px',
                    paddingTop: '20px',
                    display: 'inline-grid'
                }}>
                    Generating Trip
                </div>
                <CircularProgress color="inherit" style={{ marginLeft: 40 }} />


            </Backdrop>}
            <ChatBox />
        </div>
    )
}

export default BaseComponents