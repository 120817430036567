import * as Actions from '../../../../redux/actions'
import React, { useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PlaceHolderImage from '../../../../assets/images/placeholderImage.jpeg';
import { makeStyles, TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GetAiResponse, GetAiSuggestions, GetDestinationPrompt, GetEstablishmentPrompt, GetHotelPrompt } from './HotelChatBox.services';
import Typewriter from './TypeWriter';
import moment from 'moment';
import HotelAiResponses from './HotelAiResponses';
import { createTripThunk } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice';
import { URL_REPLACE } from '../RegExValidate';
import { useHistory } from "react-router-dom"
import { encryptID } from '../../HotelPage/hotelServices';
import { useOuterClick } from './useOuterClick';
import { useLocation } from 'react-router-dom';
import MuvGptLogo from '../AiChatBox/muvGptLogo';
import { toggleAiResults, updateAiResponses, updateAiSearching } from '../AiChatBox/AiChatBoxReducer';
import { deleteCookie, getCookie, setCookie } from '../AiChatBox/helperAi';

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    }
})

export const formattedHtml = (text) => {
    if (typeof text !== 'string' || text.length === 0) {
        return text;
    }

    return text.replace(/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g, (match, label, url) => {
        const urlObj = new URL(url);

        if (urlObj.hostname.includes('muvtravel.com')) {
            // Convert to relative URL
            const relativeUrl = urlObj.pathname + (urlObj.search ? urlObj.search : "");
            return `<a href="${relativeUrl}">${label}</a>`;
        }

        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${label}</a>`;
    });
};



const EstablishmentChatBox_ = ({ timelineStatus, isEstablishment, isDestination, establishmentDestination, destinationId, lang, establishment, getMemberAreaMuverInfo, uuid, activeTab, homepageSliderImage, setBookingFormActiveTab, homepageGeneralInfo, logo_width, is_mobile, bodyClientWidth, app_menu_height }) => {

    React.useEffect(() => {
        if (uuid) {
            getMemberAreaMuverInfo(uuid)
        }
    }, [uuid])

    const classes = useStyles();

    const history = useHistory()
    const [places, setPlaces] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const listRef = useRef()
    const member = useSelector(state => state.Member);
    const avatarUrl = member?.memberArea?.memberMuver?.muverAvatar?.fullUrl;
    const [sessionId, setSessionId] = React.useState("");
    const [tripDest, setDestination] = React.useState()
    const [creating, setCreating] = React.useState(false)
    const dispatch = useDispatch()
    const location = useLocation();
    const timelineOpen = timelineStatus;


    const aiBoxData = useSelector(state => state.AiChatBox);
    const chatResponses = aiBoxData?.responses;
    const showResults = aiBoxData?.showResults;
    const searching = aiBoxData?.searching;


    const handleMessage = React.useCallback((event) => {
        const val = event.target.value
        setMessage(val)
    }, []);


    React.useEffect(() => {
        setSessionId(Date.now())
        return (() => {
            setSessionId("")
        })
    }, [])

    React.useEffect(() => {
        let messages = getCookie("chatMessages");

        if (messages) {
            messages = JSON?.parse(messages);
            if (messages !== chatResponses) {
                dispatch(updateAiResponses([...messages])) ///prefill
            }
        }
    }, []);


    const saveToLocal = (responses) => {
        setCookie("chatMessages", JSON.stringify(responses));
    }



    const updateResponses = (data) => {
        dispatch(updateAiResponses([...data]))
        saveToLocal([...data])
    }


    const setSearching = (data) => {
        dispatch(updateAiSearching(data))
    }


    const setResults = (data) => {
        dispatch(toggleAiResults(data))
    }

    const sendMessage = async (event) => {
        if (event?.key === 'Enter' && message?.length > 0) {
            setSearching(true)

            let newMessages = [];
            let combineResponses = [...chatResponses];

            combineResponses.push([{
                sender: avatarUrl,
                text: message,
                isUser: true,

            }])
            updateResponses(combineResponses);

            if (!showResults) {
                setResults(true)
            }
            ////push user message first



            //push suggestion
            const hotelId = establishment?.generalInfo?.establishmentPricelineID;
            let result;

            if (isEstablishment) {
                result = await GetEstablishmentPrompt({ message, member_id: uuid, prompt_session_id: sessionId, id: establishmentDestination?.activeId });

            }
            else if (isDestination) {
                result = await GetDestinationPrompt({ message, member_id: uuid, prompt_session_id: sessionId, id: destinationId });

            }
            else {
                result = await GetHotelPrompt({ message, member_id: uuid, prompt_session_id: sessionId, hotelId: hotelId });

            }

            const messageResponse = result?.result?.prompt_response;
            const html = formattedHtml(messageResponse)

            newMessages.push({
                bot: true,
                text: html ?? "I am not sure I get your request, can you be more precise?",
                new: true,

            })


            ///for responses
            combineResponses.push(newMessages)
            updateResponses(combineResponses)



            setSearching(false)
            setMessage("")
        }

    }


    const scrollToBottom = () => {
        try {
            listRef.current.scrollIntoView({ block: 'end', behavior: 'instant' });
        }
        catch {

        }
    }


    const toggleResults = () => {
        setResults(!showResults)
    }

    const markMessagesAsRead = React.useCallback(() => {

        let updated = chatResponses.map(group =>
            group.map(message => {
                if (typeof message === "object" && message !== null) {
                    let updatedMessage = { ...message };
                    delete updatedMessage.new;
                    return updatedMessage;
                }
                return message;
            })
        );


        // Update the state (if needed)
        updateResponses(updated);

    }, [updateResponses, chatResponses]);


    const createTrip = React.useCallback(async () => {
        const destination = tripDest?.muv_destination ? [{
            ...tripDest?.muv_destination,
            duration: tripDest?.trip_duration || 3
        }] : null;

        if (destination) {
            setCreating(true)
            const trip = {
                destinations: destination,
                lang: lang,
                uuid: uuid || "TMPUUID",
                tripId: ""

            }
            const newTrip = await dispatch(createTripThunk(trip));
            const addedTrip = newTrip.payload;
            if (addedTrip) {
                const encryptedId = encryptID(addedTrip?.tripID)

                const url = "/trip/" + addedTrip?.tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
                history.push(url.toLowerCase())



                setCreating(false)
            }
            else {
                setCreating(false)
            }

            setTimeout(() => {
                setCreating(false)
            }, 8000);
        }

    }, [tripDest])



    React.useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 10);
    }, [chatResponses])


    const handleArrowClick = () => {
        toggleResults()
        sendMessage()

        if (showResults) {

            let combineResponses = [...chatResponses];

            combineResponses.push([{
                text: "",

            }])
            updateResponses(combineResponses);

        }//fake end animation

    }

    const innerRef = useOuterClick(ev => {
        if (showResults) {
            toggleResults()
        }
    });

    return (

        <div ref={innerRef} style={{ width: is_mobile ? "95%" : "100%", position: "fixed", bottom: is_mobile ? 15 : 35, zIndex: 200 }}>


            <AIReponsesMemo
                createTrip={createTrip}
                markMessagesAsRead={markMessagesAsRead}
                creating={creating}
                chatResponses={chatResponses}
                searching={searching}
                tripDest={tripDest}
                showResults={showResults}
                is_mobile={is_mobile}
                listRef={listRef}
            />



            <div style={{}}>
                <div style={{ zIndex: 1, marginLeft: is_mobile ? "6%" : timelineOpen ? "30.5%" : "16.5%", position: "absolute", marginTop: 10, display: "inline-block" }} >
                    <MuvGptLogo />
                </div>

                <TextField
                    classes={{ root: classes.customTextField }}
                    onKeyPress={sendMessage}
                    disabled={searching}
                    value={message}
                    style={{
                        background: "white",
                        borderRadius: 50,
                        border: "2px solid #19BC9B",
                        width: is_mobile ? "90%" : timelineOpen ? "60%" : "70%",
                        marginLeft: is_mobile ? "5%" : timelineOpen ? "30%" : "15.5%",
                        padding: 10,
                        paddingLeft: 120,
                        paddingRight: 70,
                        // border: '1px solid lightgrey',
                        font: "18px/20px Futura Lt BT",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    placeholder={
                        isEstablishment ? 'Ask anything about this establishment & its neighborhood: how to get there, opening hours, entrance fee or what are the good restaurants around, etc.?' :
                            isDestination ? 'Ask anything about this destination: what the best museums or restaurants are, how to get there from the airport, what can I do with kids, etc.?' :
                                'Ask anything about this hotel & its neighborhood: how to get there, are pets allowed, any good restaurants around, etc.?'}
                    id='aiTextBox'
                    InputProps={{ disableUnderline: true }}
                    onChange={handleMessage}
                />
                <div onClick={handleArrowClick} style={{ marginLeft: -55, cursor: "pointer", position: "relative", marginTop: 10, display: "inline-block" }}>
                    <FontAwesomeIcon size="2x" icon={showResults ? faChevronDown : faChevronUp} style={{ color: 'lightgrey', marginRight: "5px" }} />
                </div>

                {/* <div style={{         textShadow: " 1px 1px 2px grey, 0 0 1px grey, 0 0 0.2px grey", color: "#19BC9B", font: "16px/20px Futura Lt BT", width: "100%", marginTop: 4, textAlign: "center" }}>
                    You will be able to generate an AI-personalized itinerary at the end of the booking process
                </div> */}

            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {

        uuid: state.Member.authModal.uuid,
        logo_width: state.Setting.appMenu.logo.width,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        lang: state.Setting.lang,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        homepageSliderImage: state.Home.homepage_slider_image,
        homepageGeneralInfo: state.Home.homepage_general_info,
        is_mobile: state.Setting.is_mobile,
        establishment: state.FormBank.HotelSearch.establishment,
        establishmentDestination: state.Destination.establishment,
        activeTab: state.FormBank.BookingForm.activeTab,
        destinationId: state.Destination.destination.activeId,
        timelineStatus: state.FormBank.TimelineWizard.status,
    }

}

const mapDispatchToProps = {
    getMemberAreaMuverInfo: Actions.getMemberAreaMuverInfo,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
}

const EstablishmentChatBox = connect(mapStateToProps, mapDispatchToProps)(EstablishmentChatBox_)
export default React.memo(EstablishmentChatBox)


const AIReponsesMemo = React.memo(({ createTrip, markMessagesAsRead, creating, chatResponses, searching, tripDest, showResults, is_mobile, listRef }) => {
    return (
        <HotelAiResponses
            createTrip={createTrip}
            markMessagesAsRead={markMessagesAsRead}
            creating={creating}
            chatResponses={chatResponses}
            searching={searching}
            tripDestination={tripDest}
            showResults={showResults}
            scrollToBottom={() => listRef.current?.scrollIntoView({ block: 'end', behavior: 'instant' })}
            is_mobile={is_mobile}
            listRef={listRef}
        />
    );
});