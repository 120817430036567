import {
    DirectionsRenderer,
    GoogleMap,
    withGoogleMap,
} from "react-google-maps";
import { compose, lifecycle, withProps } from "recompose";
import React from "react";
import mapStyles from "../TripPage/mapStyles";
import DestinationMarkers from "../Common/destinationMarkers";
import Polyline from "react-google-maps/lib/components/Polyline";
import { useDispatch, useSelector } from "react-redux";
import { getDestinationNearbyPlaces } from "../Common/Trip/ActiveTrip.slice";
import { renderIconDestination } from "../Common/Trip/DestinationIconsMap";
import { generateMapAPIKey } from "../../../utility/utility";

const isEqual = (arr1 = [], arr2 = []) =>
    arr1.length === arr2.length &&
    arr1.every((a) => arr2.some((b) => JSON.stringify(a) === JSON.stringify(b)));

const fetchRoute = (origin, destination, transportMode, callback) => {
    const DirectionsService = new window.google.maps.DirectionsService();
    const isTrain = transportMode === "train";

    DirectionsService.route(
        {
            origin,
            destination,
            provideRouteAlternatives: true,
            optimizeWaypoints: true,
            travelMode: isTrain
                ? window.google.maps.TravelMode.TRANSIT
                : window.google.maps.TravelMode.DRIVING,
            transitOptions: {
                modes: isTrain ? [window.google.maps.TransitMode.TRAIN] : undefined,
            },
        },
        (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                callback(result);
            } else {
                console.warn("Route fetch failed", status);
            }
        }
    );
};

export const MuverTripMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${generateMapAPIKey()}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `600px` }} />,
    }),
    withGoogleMap,
    lifecycle({
        initializeMap(destinations) {
            this.setState({ directions: {}, allDirections: [], display: false });

            const mapPoints = [];

            destinations.forEach((dest) => {
                const { transportMode, latitude, longitude, mapGeo, home } = dest;
                if (home) return;

                const destPath = { lat: +latitude, lng: +longitude };
                mapPoints.push(destPath);

                const isFlight = transportMode === "plane";
                const isTrain = transportMode === "train";

                if (isFlight && mapGeo) {
                    const airportOrigin = {
                        lat: +mapGeo.origin.airportLatitude || +mapGeo.origin.latitude,
                        lng: +mapGeo.origin.airportLongitude || +mapGeo.origin.longitude,
                    };
                    const airportDest = {
                        lat: +mapGeo.destination.airportLatitude || +mapGeo.destination.latitude,
                        lng: +mapGeo.destination.airportLongitude || +mapGeo.destination.longitude,
                    };

                    const userStartLoc = {
                        lat: +mapGeo.origin.latitude,
                        lng: +mapGeo.origin.longitude,
                    };

                    const finalDestLoc = {
                        lat: +latitude,
                        lng: +longitude,
                    };

                    // ✅ Pre-flight route: origin → origin airport
                    fetchRoute(userStartLoc, airportOrigin, "car", (result) => {
                        this.setState((prev) => ({
                            allDirections: [
                                ...prev.allDirections,
                                { directions: result, transportMode: "car" },
                            ],
                        }));
                    });

                    // ✈️ Flight path: handled in render with Polyline

                    // ✅ Post-flight route: destination airport → destination
                    fetchRoute(airportDest, finalDestLoc, "car", (result) => {
                        this.setState((prev) => ({
                            allDirections: [
                                ...prev.allDirections,
                                { directions: result, transportMode: "car" },
                            ],
                        }));
                    });
                } else if (mapGeo) {
                    const path1 = {
                        lat: +mapGeo.origin.latitude,
                        lng: +mapGeo.origin.longitude,
                    };
                    const path2 = {
                        lat: +mapGeo.destination.latitude,
                        lng: +mapGeo.destination.longitude,
                    };

                    fetchRoute(path1, path2, transportMode, (result) => {
                        this.setState((prev) => ({
                            allDirections: [
                                ...prev.allDirections,
                                { directions: result, transportMode },
                            ],
                        }));
                    });
                }
            });

            if (mapPoints.length > 0) {
                const bounds = new window.google.maps.LatLngBounds();
                mapPoints.forEach((pt) => bounds.extend(pt));
                this.props.refMap.current.fitBounds(bounds);
                this.props.setMapCenter(bounds.getCenter());
                this.props.setZoom(8);
            }
        },

        componentDidMount() {
            this.initializeMap(this.props.destinations);
        },

        componentDidUpdate(prevProps) {
            if (
                prevProps.tripId !== this.props.tripId ||
                !isEqual(prevProps.destinations, this.props.destinations)
            ) {
                this.initializeMap(this.props.destinations);
            }
        },
    })
)((props) => {
    const dispatch = useDispatch();
    const destinationNearByPlaces = useSelector(
        (state) => state.ActiveTrip.destinationNearByPlaces
    );

    const [trackInfoArray, setTrackInfoArray] = React.useState([]);
    const [trackDestArray, setTrackDestArray] = React.useState([]);

    const pushPlacesWindow = (id) => setTrackInfoArray([id]);
    const pushDestWindow = (id) => setTrackDestArray([id]);
    const clearDestWindow = () => setTrackDestArray([]);
    const clearPlacesWindow = () => setTrackInfoArray([]);

    const checkDestOpen = (id) => trackDestArray.includes(id);
    const checkWindowOpen = (id) => trackInfoArray.includes(id);

    React.useEffect(() => {
        if (props.overviewPath) {
            dispatch(
                getDestinationNearbyPlaces({
                    coords: props.overviewPath,
                    selectedTypes: props.selectedTypes || [],
                })
            );
        }
    }, [props.overviewPath]);

    return (
        <GoogleMap
            defaultZoom={props.zoom}
            zoom={props.zoom}
            defaultCenter={props.mapCenter}
            center={props.mapCenter}
            defaultOptions={{
                controlSize: 20,
                mapTypeControl: false,
                fullscreenControl: false,
                maxZoom: 17,
                minZoom: 3,
                styles: mapStyles,
                zoomControl: true,
                panControl: true,
                draggable: true,
                scrollwheel: true,
            }}
            ref={props.refMap}
            onClick={() => {
                clearDestWindow();
                clearPlacesWindow();
            }}
        >
            {/* Flight path */}
            {props.destinations?.map((dest, index) => {
                const isFlight = dest.transportMode === "plane";
                const geo = dest.mapGeo;
                if (!isFlight || !geo) return null;

                const origin = {
                    lat: +geo.origin.airportLatitude || +geo.origin.latitude,
                    lng: +geo.origin.airportLongitude || +geo.origin.longitude,
                };
                const destination = {
                    lat: +geo.destination.airportLatitude || +geo.destination.latitude,
                    lng: +geo.destination.airportLongitude || +geo.destination.longitude,
                };

                return (
                    <Polyline
                        key={`flight-${index}`}
                        path={[origin, destination]}
                        options={{
                            suppressMarkers: true,
                            strokeColor: "#19BC9B",
                            strokeOpacity: 0.5,
                            strokeWeight: 4,
                            geodesic: true,
                            icons: [
                                {
                                    icon: renderIconDestination("plane"),
                                    offset: "50%",
                                },
                            ],
                        }}
                    />
                );
            })}

            {/* Markers */}
            {props.destinations?.map((dest, index) => (
                <DestinationMarkers
                    key={index}
                    lengthDestinations={props.destinations.length}
                    destination={dest}
                    color={{
                        fill: "rgba(25, 188, 155, 1)",
                        stroke: "rgba(25, 188, 155, 0.4)",
                    }}
                    index_destination={index}
                    mapProps={props}
                    isRegularTrip={false}
                    pushDestWindow={pushDestWindow}
                    clearDestWindow={clearDestWindow}
                    checkDestOpen={checkDestOpen}
                    isItinerary={true}
                    dblClickDestination={props?.dblClickDestination}
                />
            ))}

            {/* Directions */}
            {props.allDirections?.map((route, index) => (
                <DirectionsRenderer
                    key={`dir-${index}`}
                    directions={route.directions}
                    options={{
                        preserveViewport: true,
                        suppressMarkers: true,
                        polylineOptions: {
                            strokeColor: "#19BC9B",
                            strokeOpacity: 1,
                            strokeWeight: 4,
                            icons: [
                                {
                                    icon: renderIconDestination(route.transportMode),
                                    offset: "50%",
                                    fixedRotation: true,
                                },
                            ],
                        },
                    }}
                />
            ))}
        </GoogleMap>
    );
});
