import { useEffect, useRef, useLayoutEffect } from 'react';

export function useOuterClick(callback) {
  const callbackRef = useRef(callback);
  const innerRef = useRef(null);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    }

    document.addEventListener('mousedown', handleClick, true); // note: capture = true
    return () => document.removeEventListener('mousedown', handleClick, true);
  }, []);

  return innerRef;
}
