import * as Actions from '../../../../redux/actions'

import {
    CalendarDuotoneIcon,
    PlaneArrivalIcon,
    PlaneDepartureIcon,
    TimesIcon,
} from "../SVGBank"

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getAirportsListThunk } from '../../FlightsPage/services/Flights.thunks'
import moment from 'moment'
import { getIPFromAmazon } from '../../HotelPage/hotelServices'
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { calculatePercentageDifference, getDates, getFlightsByDate } from '../../FlightsPage/services/flights.services'
import { environment } from '../../../../Environments/environment'

const FlightFormFlightElementRoundTrip_ = ({ embedded, flipDirection, departingFlight, returningFlight, setFlightsData, is_mobile, lang, error, getAirportList }) => {
    const dispatch = useDispatch()
    const useEmbedded = environment?.production ? false : embedded
    const airportsList = useSelector(state => state.Flights.airportsList)
    let departingDate = departingFlight.departureDateFormat && moment(departingFlight.departureDateFormat).toDate();
    let returningDate = returningFlight.departureDateFormat && moment(returningFlight.departureDateFormat).toDate();
    let [cachedPrices, setCachedPrices] = React.useState()
    let [searching, setSearching] = React.useState(false)
    const location = useLocation()
    const isFlightsPage = location && location.pathname.includes("/flight");
    const [airportFrom, setAirportFrom] = React.useState(departingFlight.airportFrom)
    const [airportTo, setAirportTo] = React.useState(departingFlight.airportTo)

    const cabinClass = useSelector(state => state.Flights.flightData.cabinClass);
    const flightMode = useSelector(state => state.Flights.flightData.flightMode);
    const numberAdult = useSelector(state => state.Flights.flightData.numberAdult);
    const numberChild = useSelector(state => state.Flights.flightData.numberChild);
    const flights = useSelector(state => state.Flights.flightData.flights);
    const currency = useSelector(state => state.Setting.currency);
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";

    const [startDate, setStartDate] = React.useState(departingDate);
    const [endDate, setEndDate] = React.useState(returningDate);


    const endDateRef = React.useRef(null);

    const openDatePicker = () => {
        if (endDateRef.current) {
            endDateRef.current.setOpen(true);
        }
    };



    const handleSelectedAirportsFrom = (airports) => {
        if (airports !== undefined) {
            setAirportFrom(airports.length > 0 ? airports[0] : null)
            setFlightsData(departingFlight, airports.length > 0 ? airports[0] : null, airportTo, departingDate)
        }
    }

    const handleSelectedAirportsTo = (airports) => {
        if (airports !== undefined) {
            setAirportTo(airports.length > 0 ? airports[0] : null)
            setFlightsData(returningFlight, airports.length > 0 ? airports[0] : null, airportFrom, returningDate)

        }
    }

    const handleChangeDepartureDate = (date) => {
        setFlightsData(departingFlight, airportFrom, airportTo, date)

        openDatePicker()
    }

    const handleSelectDate = (date) => {
        // Reset selection if both dates already exist or selecting before start
        if (!startDate || (startDate && endDate)) {
            setStartDate(date);
            setEndDate(null);
            setFlightsData(departingFlight, airportFrom, airportTo, date);
        } else if (moment(date).isSameOrAfter(startDate, 'day')) {
            setEndDate(date);
            setFlightsData(departingFlight, airportFrom, airportTo, startDate);
            setFlightsData(returningFlight, airportTo, airportFrom, date);
        } else {
            // Start new range if selected date is before current start
            setStartDate(date);
            setEndDate(null);
            setFlightsData(departingFlight, airportFrom, airportTo, date);
        }
    };


    const handleChangeReturnDate = (date) => {
        setFlightsData(returningFlight, airportTo, airportFrom, date)
    }

    const handleSearchAirports = (query) => {
        dispatch(getAirportsListThunk(query))
    }


    React.useEffect(() => {
        setFlightsData(returningFlight, airportTo, airportFrom, returningDate)
    }, [airportFrom]);

    React.useEffect(() => {
        setFlightsData(departingFlight, airportFrom, airportTo, departingDate)
    }, [airportTo]);


    React.useEffect(() => {
        setAirportFrom(departingFlight.airportFrom)
        setAirportTo(departingFlight.airportTo)
    }, [departingFlight]);


    React.useEffect(() => {
        const getAddress = async () => {
            const airport = await getIPFromAmazon();
            if (airport && !airportFrom) { //airportFrom not set
                setAirportFrom(airport)
                setFlightsData(departingFlight, airport, airportTo, departingDate)
            }
        }
        if (!isFlightsPage) {
            getAddress()
        }

    }, [])

    const renderDayContents = React.useCallback((day, date) => {
        const tooltipText = `Tooltip for date: ${date}`;
        let priceColor = `var(--mainOrange)`;
        let price = null
        if (cachedPrices?.length > 0) {

            const formattedDate = moment(date).format("YYYY-MM-DD");

            const filteredCached = cachedPrices?.filter((data) => data?.price);
            const cachedPrice = filteredCached?.find((data) => data?.date === formattedDate);
            const avgPrice = filteredCached?.length > 0 ? filteredCached?.reduce((a, b) => { return a + (Number(b?.price)) }, 0) / filteredCached?.length : 0;
            price = cachedPrice?.price ? cachedPrice?.price : null;
            const percentDiff = price ? calculatePercentageDifference(avgPrice, price) : 0;
            const lowPrice = `var(--mainGreen)`;
            const highPrice = `var(--mainRed)`;
            priceColor = percentDiff > 30 ? lowPrice : percentDiff < -30 ? highPrice : `var(--mainOrange)`;

        }


        return <div style={{
            // height: 60,

        }}>
            {price && !is_mobile && useEmbedded ? <div style={{
                textAlign: is_mobile || !price ? "center" : "right",
                padding: 5,
                borderRadius: 10,
                borderBottom: `5px solid ${priceColor}`,
            }}>
                <div >{day}</div>
                <div >{currency?.selected?.symbol || "$"}{price}</div>
            </div> :
                <div>
                    {useEmbedded ?
                        <div style={{
                            borderBottom: `5px solid white`,
                            textAlign: "center",
                            padding: 5,
                            borderRadius: 10,

                        }}>
                            <div style={{ paddingTop: 0 }}>{day}</div>
                            <div style={{ visibility: "hidden" }} >fake price</div>
                        </div> :
                        <div style={{ paddingTop: 0 }}>{day}</div>
                    }
                </div>
            }

        </div>;
    }, [cachedPrices, is_mobile, useEmbedded]);

    const calendarClosed = async () => {
        let priceData = []
        const dataToBookingFlights = {
            flightMode: flightMode,
            cabinClass: cabinClass,
            numberAdult: numberAdult,
            numberChild: numberChild,
            flights: flights,
            currency: fullCurrency

        }
        // const differenceDays = moment(returningDate)?.diff(moment(departingDate), 'days')  ?? 0;
        let dates = getDates(moment(departingDate).subtract(7, "days"), moment(departingDate).add(30, "days"))

        dates = dates?.map((date) => {
            return {
                ...dataToBookingFlights,
                flights: [
                    {
                        ...dataToBookingFlights?.flights?.[0],
                        departureDateFormat: date
                    },
                    {
                        ...dataToBookingFlights?.flights?.[1],
                        departureDateFormat: date
                    },
                ],
                dateFormat: date
            }
        })


        if (dates?.length > 0 && airportFrom && airportTo) {
            if (!searching) {
                setSearching(true)
                await Promise.all(dates.map(async (date) => {
                    const data = await getFlightsByDate(date);

                    const price = data?.itinerary_data?.[0]?.price_details?.display_total_fare;
                    priceData.push({
                        date: date?.dateFormat,
                        price: price
                    })
                    setCachedPrices(priceData)
                }))

                setSearching(false)
            }

        }

    }

    React.useEffect(() => {
        if (startDate && endDate) {
            setFlightsData(departingFlight, airportFrom, airportTo, startDate);
            setFlightsData(returningFlight, airportTo, airportFrom, endDate);
        }
    }, [startDate, endDate]);


    return (
        <div className={`${useEmbedded ? `embeddedDates` : ''} row m-0 p-0 w-100 mt-3 flightRoundTripForm`} style={{ padding: "1px", border: airportFrom !== null && airportTo !== null && airportFrom.iata === airportTo.iata ? "1px solid red" : "" }}>
            <div className="col-12 col-md-4 p-0">
                <div className="position-relative fromAirport" style={{ border: airportFrom === null && error ? "1px solid red" : "" }}>
                    <AsyncTypeahead

                        id="flight-airport-from-typeahead-departing-from"
                        labelKey={(option) => {
                            return (option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                                `${option.iata} - ${option.airport}`)
                        }}
                        filterBy={() => true}
                        onChange={(e) => handleSelectedAirportsFrom(e)}
                        options={airportsList}
                        placeholder="From"
                        shouldSelect={true}
                        minLength={1}
                        onSearch={query => handleSearchAirports(query)}
                        renderMenuItemChildren={(option) => {
                            return (<div key={option?.id} user={option} style={{
                                font: option.type === "main" ? "16px/30px Futura Md BT" : "",
                                paddingLeft: option.type === "sub" ? 20 : 0
                            }}>

                                {option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                                    `${option.iata} - ${option.airport}`
                                }
                            </div>)
                        }}
                        selected={airportFrom ? [airportFrom] : []}
                        isLoading={false}
                        delay={100}
                        clearButton={true}
                        multiple={false}
                    />
                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                        <PlaneDepartureIcon width={15} height={15} />
                    </div>
                </div>
            </div>




            <div className="col-12 col-md-4 p-0">

                <div className="position-relative toAirport" style={{ border: airportTo === null && error ? "1px solid red" : "" }}>
                    <AsyncTypeahead

                        id="light-airport-to-typeahead-going-to"
                        labelKey={(option) => {
                            return (option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                                `${option.iata} - ${option.airport}`)
                        }}
                        onChange={(e) => handleSelectedAirportsTo(e)}
                        options={airportsList}
                        placeholder="To"
                        filterBy={() => true}
                        shouldSelect={true}
                        minLength={1}
                        onSearch={query => handleSearchAirports(query)}
                        renderMenuItemChildren={(option) => {
                            return (<div key={option.id} user={option} style={{
                                font: option.type === "main" ? "16px/30px Futura Md BT" : "",
                                paddingLeft: option.type === "sub" ? 20 : 0
                            }}>

                                {option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                                    `${option.iata} - ${option.airport}`
                                }
                            </div>)
                        }}
                        isLoading={false}
                        selected={airportTo ? [airportTo] : []}
                        delay={100}
                        clearButton={true}
                        multiple={false}
                    />
                    <div className="switchButton" style={{

                        top: is_mobile ? "10px" : "14px",
                        borderRadius: '50%', width: 35,
                    }} onClick={flipDirection}>
                        <div style={{ padding: 1, cursor: "pointer", textAlign: "center" }}>
                            <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ color: '#202020' }} />
                        </div>
                    </div>

                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                        <PlaneArrivalIcon width={15} height={15} />
                    </div>

                </div>
            </div>
            <div className="col-6 col-md-2 p-0">
                <div className="position-relative" style={{ border: departingDate === "" && error ? "1px solid red" : "" }}>
                    <DatePicker
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        selectsEnd
                        shouldCloseOnSelect={false}
                        showPopperArrow={false}
                        onCalendarClose={calendarClosed}
                        monthsShown={useEmbedded && !is_mobile ? 1 : 1}
                        isClearable={false}
                        renderDayContents={renderDayContents}
                        // startDate={departingDate}
                        // endDate={returningDate}
                        onChange={(date) => handleSelectDate(date)}
                        placeholderText="Departure"
                        dateFormat={"yyyy/MM/dd" || "yyyy-mm-dd"}
                        minDate={new Date()}
                        // maxDate={returningDate ? new Date(returningDate) : null}
                        className="form-control" />
                    <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                        <CalendarDuotoneIcon width={15} height={15} />
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-2 p-0">
                <div className="position-relative dateTo" style={{ border: returningDate === "" && error ? "1px solid red" : "" }}>
                    <DatePicker
                        selected={endDate}
                        startDate={startDate}
                        endDate={endDate}
                        selectsEnd
                        shouldCloseOnSelect={false}
                        showPopperArrow={false}
                        onCalendarClose={calendarClosed}
                        monthsShown={useEmbedded && !is_mobile ? 2 : 1}
                        isClearable={false}
                        renderDayContents={renderDayContents}
                        // startDate={departingDate}
                        // endDate={returningDate}
                        onChange={(date) => handleSelectDate(date)}
                        placeholderText="Return"
                        dateFormat={"yyyy/MM/dd" || "yyyy-mm-dd"}
                        minDate={new Date()}
                        // maxDate={returningDate ? new Date(returningDate) : null}
                        className="form-control" />
                    <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                        <CalendarDuotoneIcon width={15} height={15} />
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    is_mobile: state.Setting.is_mobile,
})

const mapDispatchToProps = {
    getAirportList: Actions.getAirportList,
}

const FlightFormFlightElementRoundTrip = connect(mapStateToProps, mapDispatchToProps)(FlightFormFlightElementRoundTrip_)
export default FlightFormFlightElementRoundTrip
