import * as Actions from '../../../../redux/actions'
import React, { useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PlaceHolderImage from '../../../../assets/images/placeholderImage.jpeg';
import { TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GetAiResponse, GetAiSuggestions } from './AiChatBox.services';
import moment from 'moment';
import AiResponses from './AiResponses';
import { createDestTripThunk, createPersonalizedTripThunk, createTripThunk } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice';
import { URL_REPLACE } from '../RegExValidate';
import { useHistory } from "react-router-dom"
import { encryptID } from '../../HotelPage/hotelServices';
import { useLocation } from 'react-router-dom';
import { formattedHtml } from '../HotelChatBox/HotelChatBox';
import MuvGptLogo from './muvGptLogo';
import { toggleAiResults, updateAiResponses, updateAiSearching, updateTripDest } from './AiChatBoxReducer';
import { savePlaceTimeline } from '../../BookingPage/PaymentPage/payment.thunk';
import { deleteCookie, getCookie, setCookie } from './helperAi';
import { useOuterClick } from '../HotelChatBox/useOuterClick';

const AiChatBox_ = ({ optimizeTimeline, setTimeLineStatus, timelineStatus, lang, getMemberAreaMuverInfo, uuid, activeTab, homepageSliderImage, setBookingFormActiveTab, homepageGeneralInfo, logo_width, is_mobile, bodyClientWidth, app_menu_height }) => {

    React.useEffect(() => {
        if (uuid) {
            getMemberAreaMuverInfo(uuid)
        }
    }, [uuid])



    const history = useHistory()

    const [places, setPlaces] = React.useState([]);
    const [message, setMessage] = React.useState("");

    const listRef = useRef()
    const member = useSelector(state => state.Member);
    const avatarUrl = member?.memberArea?.memberMuver?.muverAvatar?.fullUrl;
    const [sessionId, setSessionId] = React.useState("");
    const [typeIndex, setTypeIndex] = React.useState(0)
    const [creating, setCreating] = React.useState(false)
    const [personalizing, setPersonalizing] = React.useState(false)
    const dispatch = useDispatch()
    const location = useLocation();

    const aiBoxData = useSelector(state => state.AiChatBox);
    const chatResponses = aiBoxData?.responses;
    const showResults = aiBoxData?.showResults;
    const searching = aiBoxData?.searching;
    const tripDest = aiBoxData?.tripDest;

    const timelineOpen = timelineStatus && location?.pathname !== "/" && !location?.pathname?.includes("muver");

    const handleMessage = React.useCallback((event) => {
        const val = event.target.value
        setMessage(val)
    }, []);


    const innerRef = useOuterClick(ev => {

        if (showResults) {
            toggleResults()
        }
    });



    React.useEffect(() => {
        let messages = getCookie("chatMessages");

        if (messages) {
            messages = JSON?.parse(messages);
            if (messages !== chatResponses) {
                dispatch(updateAiResponses([...messages])) ///prefill
            }
        }
    }, []);


    const saveToLocal = (responses) => {
        setCookie("chatMessages", JSON.stringify(responses));
    }


    const updateResponses = (data) => {
        dispatch(updateAiResponses([...data]))
        saveToLocal([...data])
    }




    const setSearching = (data) => {
        dispatch(updateAiSearching(data))
    }

    const setDestination = (data) => {
        dispatch(updateTripDest(data))
    }




    const sendMessage = async (event) => {
        if (event?.key === 'Enter' && message?.length > 0) {
            setSearching(true)

            let newMessages = [];


            let combineResponses = [...chatResponses];

            combineResponses.push([{
                sender: avatarUrl,
                text: message,
                isUser: true

            }])

            updateResponses(combineResponses);

            if (!showResults) {
                setResults(true)
            }
            ////push user message first


            //push suggestion
            const dataMessage = message;
            const result = await GetAiSuggestions({ message: dataMessage, member_id: uuid, muvgpt_session_id: sessionId });



            const resultSessionId = result?.result?.muvgpt_session_id;
            const dest = result?.result?.tripAI;

            if (dest?.destination?.id && dest?.destination?.establishment_ids?.length > 0) {
                setDestination(dest)
            }

            setSessionId(resultSessionId)
            const messageResponse = result?.result?.prompt_response;
            const html = formattedHtml(messageResponse)



            newMessages.push({
                bot: true,
                text: html ?? "I am not sure I get your request, can you be more precise?",
                new: true
            })



            ///for responses



            combineResponses.push(newMessages)
            updateResponses(combineResponses)



            setSearching(false)
            setMessage("")
        }

    }


    const scrollToBottom = () => {
        try {
            listRef.current.scrollIntoView({ block: 'end', behavior: 'instant' });
        }
        catch {

        }
    }


    const toggleResults = () => {
        setResults(!showResults)
    }

    const setResults = (data) => {
        dispatch(toggleAiResults(data))
    }






    const savePlace = React.useCallback(async (tripId, tripDestId, establishmentIds, duration = 3) => {
        if (!establishmentIds.length) return;

        const totalPlaces = establishmentIds.length;
        const placesPerDay = Math.floor(totalPlaces / duration);
        const extraPlaces = totalPlaces % duration; // Extra places to distribute

        let currentIndex = 0;
        const allPromises = []; // Store all API calls as promises

        for (let d = 1; d <= duration; d++) {
            const placesForToday = placesPerDay + (d <= extraPlaces ? 1 : 0);

            for (let i = 0; i < placesForToday; i++) {
                if (currentIndex >= totalPlaces) break; // Avoid out of bounds

                const establishmentId = establishmentIds[currentIndex];

                const saveData = {
                    postData: {
                        tripId: tripId,
                        uuid: uuid,
                        tripDestinationId: tripDestId,
                        dayInDestination: d, // Assign evenly distributed days
                        placeId: establishmentId,
                    },
                    lang: lang,
                    uuid: uuid || null,
                };

                // Push each dispatch call as a Promise
                allPromises.push(
                    dispatch(savePlaceTimeline(saveData))
                        .then(() => console.log(`Saved place: ${establishmentId} on Day ${d}`))
                        .catch((error) => console.error(`Error saving place: ${establishmentId} on Day ${d}`, error))
                );

                currentIndex++;
            }
        }

        // Run all API calls asynchronously in parallel
        await Promise.all(allPromises);
        console.log("✅ All places saved successfully!");
    }, [uuid, lang, dispatch]);



    const personalizeTrip = React.useCallback(async () => {
        history.push("/register/ai")

    }, [tripDest])



    const createTrip = React.useCallback(async () => {
        const destination = tripDest?.destination ? [{
            ...tripDest?.destination,
            duration: tripDest?.trip_duration || 3,
            destinationId: tripDest?.destination?.id,
            startDateFormat: "",
            startDate: "",
        }] : null;


        if (destination) {
            setCreating(true)
            const trip = {
                destinations: destination,
                lang: lang,
                uuid: uuid || "TMPUUID",
                tripId: ""

            }
            const newTrip = await dispatch(createDestTripThunk(trip));
            const addedTrip = newTrip.payload;
            if (addedTrip) {
                const tripID = addedTrip?.id;

                if (tripID) {
                    toggleResults()

                    const estIDs = destination?.[0]?.establishment_ids;
                    const td_id = addedTrip?.tripDestinations?.[0]?.id;
                    const tripName = addedTrip?.trip_name;
                    const duration = addedTrip?.nb_nights ? Number(addedTrip?.nb_nights) : 3;
                    await savePlace(tripID, td_id, estIDs, duration)

                    await optimizeTimeline(lang, uuid, tripID, null);
                    // optimize trip///

                    if (tripName && addedTrip?.id) {
                        const encryptedId = encryptID(String(tripID));
                        const url = "/trip/" + tripName?.replace(URL_REPLACE, "-") + "/" + encryptedId;
                        history.push(url.toLowerCase())
                    }

                    setCreating(false)
                }

            }
            else {
                setCreating(false)
            }

            setTimeout(() => {
                setCreating(false)
            }, 8000);
        }

    }, [tripDest])


    const markMessagesAsRead = React.useCallback(() => {

        let updated = chatResponses.map(group =>
            group.map(message => {
                if (typeof message === "object" && message !== null) {
                    let updatedMessage = { ...message };
                    delete updatedMessage.new;
                    return updatedMessage;
                }
                return message;
            })
        );


        // Update the state (if needed)
        updateResponses(updated);

    }, [updateResponses, chatResponses]);






    React.useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 50);
    }, [chatResponses])


    const handleArrowClick = () => {
        toggleResults()
        sendMessage()

        if (showResults) {

            let combineResponses = [...chatResponses];

            combineResponses.push([{
                text: "",

            }])

            updateResponses(combineResponses);

        }//fake end animation


        // if(showResults){ //clear messages
        //     setChatMessages([])
        //     setChatResponses([])
        //     setChatTexts([])
        // }
    }

    const pathname = location?.pathname;
    const isHome = pathname?.includes("home") || pathname === "/";
    const isFlightPage = pathname?.includes("/flight/search")


    if (isHome && activeTab !== "muvGpt" || (isHome && activeTab === "muvGpt" && !showResults)) {
        return null
    }


    return (

        <div ref={innerRef} style={{ width: is_mobile ? "95%" : "100%", position: "fixed", bottom: 18, zIndex: 400 }}>


            <AIReponsesMemo
                createTrip={createTrip}
                markMessagesAsRead={markMessagesAsRead}
                personalizeTrip={personalizeTrip}
                personalizing={personalizing}
                creating={creating}
                chatResponses={chatResponses}
                searching={searching}
                tripDest={tripDest}
                showResults={showResults}
                scrollToBottom={scrollToBottom}
                is_mobile={is_mobile}
                listRef={listRef}
            />



            <div style={{}}>
                <div style={{ zIndex: 1, marginLeft: is_mobile ? "6%" : timelineOpen ? "30.5%" : "16.5%", position: "absolute", marginTop: 10, display: "inline-block" }} >
                    <MuvGptLogo />
                </div>
                {/* <img src='https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo.png'
                    alt="logo" style={{ zIndex: 1, marginLeft: is_mobile ? "7%" : timelineOpen ? "31%" : "17%", height: 35, width: 35, borderRadius: "50%", position: "absolute", marginTop: 8, display: "inline-block" }} /> */}
                <TextField
                    onKeyPress={sendMessage}
                    disabled={searching}
                    value={message}
                    style={{
                        background: "white",
                        borderRadius: 50,
                        width: is_mobile ? "90%" : timelineOpen ? "60%" : "70%",
                        marginLeft: is_mobile ? "5%" : timelineOpen ? "30%" : "15.5%",
                        padding: 10,
                        paddingLeft: 120,
                        paddingRight: 70,
                        border: '1px solid lightgrey',
                        font: "18px/20px Futura Lt BT",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    placeholder={isFlightPage ? 'Ask anything about your destination or flight: places to stay, things to do, how to get from the airport to downtown…' : 'Ask anything for you next trip: find me a flight,  where can I stay, what should I visit, Just be precise and mûvGPT will find you the best places and deals.'}
                    id='aiTextBox'
                    InputProps={{ disableUnderline: true }}
                    onChange={handleMessage}
                />
                <div onClick={handleArrowClick} style={{ marginLeft: -55, cursor: "pointer", position: "relative", marginTop: 10, display: "inline-block" }}>
                    <FontAwesomeIcon size="2x" icon={showResults ? faChevronDown : faChevronUp} style={{ color: 'lightgrey', marginRight: "5px" }} />
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    uuid: state.Member.authModal.uuid,
    logo_width: state.Setting.appMenu.logo.width,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,
    timelineStatus: state.FormBank.TimelineWizard.status,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    homepageSliderImage: state.Home.homepage_slider_image,
    homepageGeneralInfo: state.Home.homepage_general_info,
    is_mobile: state.Setting.is_mobile,
    activeTab: state.FormBank.BookingForm.activeTab,
})

const mapDispatchToProps = {
    getMemberAreaMuverInfo: Actions.getMemberAreaMuverInfo,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
    optimizeTimeline: Actions.optimizeTimeline,
    setTimeLineStatus: Actions.setTimeLineStatus,
}

const AiChatBox = connect(mapStateToProps, mapDispatchToProps)(AiChatBox_)
export default React.memo(AiChatBox)


const AIReponsesMemo = React.memo(({
    createTrip,
    markMessagesAsRead,
    personalizeTrip,
    personalizing,
    creating,
    chatResponses,
    searching,
    tripDest,
    showResults,
    scrollToBottom,
    is_mobile,
    listRef
}) => {
    return (
        <AiResponses
            createTrip={createTrip}
            markMessagesAsRead={markMessagesAsRead}
            personalizeTrip={personalizeTrip}
            personalizing={personalizing}
            creating={creating}
            chatResponses={chatResponses}
            searching={searching}
            tripDestination={tripDest}
            showResults={showResults}
            scrollToBottom={scrollToBottom}
            is_mobile={is_mobile}
            listRef={listRef}
        />
    );
});